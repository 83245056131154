export const routesWithNewSidebar = [
  "#/invitations",
  "#/invitations/load-csv",
  "#/search-bitoria",
  "#/search-bitoria/results",
  "#/prisma",
  "#/offers",
  "#/offers/new",
  "#/makita",
];
